<template>
  <b-card title="List POS Code Master">
    <b-modal v-model="modalDelete" title="Delete POS Code">
    <!-- Alert before Delete-->
      <b-container>
        <b-row>
          <b-col cols="12" >
            <div class="d-flex">
              Are you sure want to delete this {{selectPostal.postal}} ?
            </div>
          </b-col>
        </b-row>
      </b-container>

      <template #modal-footer>
        <div class="w-100 d-flex justify-content-center align-items-center">
          <b-button variant="danger" class="ml-1" @click="confirmDelete">Confirm</b-button>
        </div>
      </template>
    </b-modal>
    <b-modal v-model="downloadModal" title="Download POS">
    <!-- Alert before Delete-->
      <b-container>
        <b-row>
          <b-col cols="12" >
            <div class="d-flex">
              Are you sure want to delete this {{selectPostal.postal}} ?
            </div>
          </b-col>
        </b-row>
      </b-container>

      <template #modal-footer>
        <div class="w-100 d-flex justify-content-center align-items-center">
          <b-button variant="danger" class="ml-1" @click="confirmDelete">Confirm</b-button>
        </div>
      </template>
    </b-modal>
    <!-- End of Modal Needed on this page-->
    <b-col cols="12">
      <b-row>
        <b-col cols="12" md="3" class="hide-on-mobile" style="margin-bottom:15px">
          <b-btn
          v-if="permission.add"
          variant="success"
          :to="{name:'post-code-add'}"
          >
            Add POS Code
          </b-btn>
        </b-col>
        <b-col cols="12" class="hide-on-desktop" style="margin-bottom:15px">
          <b-btn
          v-if="permission.add"
          variant="success"
          :to="{name:'post-code-add'}"
          >
            Add POS Code
          </b-btn>
        </b-col>
        <b-col cols="12" md="3" class="hide-on-mobile" style="margin-bottom:15px">
          <b-btn variant="primary" :disabled="postalAll.length<=0">
            <download-excel 
            :data="downloadFormat" 
            :fields="downloadFields"
            name="POS_Code.xls" 
            class="download-border">Download</download-excel>  
          </b-btn>
        </b-col>
        <b-col cols="2" class="hide-on-desktop" style="margin-bottom:15px">
          <b-btn variant="primary" :disabled="postalAll.length<=0">
            <download-excel 
            :data="downloadFormat" 
            :fields="downloadFields"
            name="POS_Code.xls" 
            class="download-border">Download</download-excel>  
          </b-btn>
        </b-col>
        <b-col style="margin-bottom:15px" class="hide-on-desktop" cols="2"/>
        <b-col style="margin-bottom:15px" cols="12" md="4">
          <b-form-input
            v-model="search"
            type="search"
            placeholder="Search by Model Number"
          />
        </b-col>
        <b-col cols="12" md="2" style="margin-bottom:15px">
          <b-btn variant="primary" @click="searchByModel()">
            Search
          </b-btn>
        </b-col>

        <b-col style="margin-bottom:15px" cols="12" md="4">
          <b-form-input
            v-model="searchPostal"
            type="search"
            placeholder="Search by Postal"
          />
        </b-col>
        <b-col cols="12" md="2" style="margin-bottom:15px">
          <b-btn variant="primary" @click="searchByPostal()">
            Search
          </b-btn>
        </b-col>

        <b-col cols="12" class="table-responsive">
          <b-table
            :items="postal"
            :fields="fields"
            :busy.sync="tableLoading"
            show-empty
          >
            <!--<template #cell(itemModel)="row">
              {{getItem(row.item.itemModel)?getItem(row.item.itemModel).itemModel:''}}
            </template>
            -->
            <template #cell(skipScan)="row">
              {{row.item.skipScan||"false"}}
            </template>
            <template #cell(actions)="row">
              <b-dropdown  id="dropdown-dropleft" variant="primary" size="sm" text="Menu" no-caret>
                <template #button-content>
                  <feather-icon size="1x" icon="MenuIcon" />
                </template>
                <b-dropdown-item style="text-align:center">
                  <b-button v-if="permission.edit" @click="editPostal(row)" variant="outline-primary" class="mr-1">
                    <feather-icon icon="EditIcon"/>
                  </b-button>
                </b-dropdown-item>
                <b-dropdown-item style="text-align:center">
                  <b-button v-if="permission.delete" @click="deletePostal(row.item)" variant="danger" class="mr-1">
                    <feather-icon icon="Trash2Icon"/>
                  </b-button>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
        </b-col>

        <b-col cols="12">
          <b-pagination
            :total-rows="totalRows"
            :per-page="perPage"
            @change="onPageChange"
            v-model="currentPage"
            style="float: right"
          />
        </b-col>

        <!--

        <b-col style="margin: 15px 0 0 0" cols="12">
          <b-table
            :items="postal"
            :fields="fields"
            :filter="search"
            :per-page="perPage"
            :current-page="currentPage"
            responsive
          >-->
            <!-- <template #cell(itemModel)="row">
              {{getItem(row.item.itemModel)?getItem(row.item.itemModel).itemModel:''}}
            </template> 
            <template #cell(skipScan)="row">
              {{row.item.skipScan||"false"}}
            </template>
            <template #cell(actions)="row">
              <b-dropdown  id="dropdown-dropleft" variant="primary" size="sm" text="Menu" no-caret>
                <template #button-content>
                  <feather-icon size="1x" icon="MenuIcon" />
                </template>
                <b-dropdown-item style="text-align:center">
                  <b-button v-if="permission.edit" @click="editPostal(row)" variant="outline-primary" class="mr-1">
                    <feather-icon icon="EditIcon"/>
                  </b-button>
                </b-dropdown-item>
                <b-dropdown-item style="text-align:center">
                  <b-button v-if="permission.delete" @click="deletePostal(row.item)" variant="danger" class="mr-1">
                    <feather-icon icon="Trash2Icon"/>
                  </b-button>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
        </b-col>

        <b-col cols="12">
          <b-pagination
            :total-rows="postal.length"
            :per-page="perPage"
            v-model="currentPage"
            style="float: right"
          />
        </b-col>
        -->
      </b-row>
    </b-col>
  </b-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import axios from '@/axios';
import {userAccess} from '@/utils/utils'

export default {
  data() {
    return {
      downloadModal:false,
      fields: [
        { key: 'postal', label: 'Pos Code', filterable: true, sortable: true },
        { key: 'itemModel', label: 'Model', filterable: true, sortable: true },
        { key: 'skipScan', label:'Skip Scan' },
        { key: 'actions', label: 'Actions' },
      ],
      search: '',
      searchPostal: '',
      postal: [],
      postalAll: [],
      itemCatalog:[],
      currentPage: 1,
      perPage: 10,
      totalRows: 1,
      tableLoading: false,
      modalDelete:false,
      donwloadModal:false,
      selectPostal:''
    }
  },
  mounted() {
    
  },
  methods: {
    searchByModel(){
      this.currentPage=1;
      this.getPostal({ 
        page: this.currentPage, 
        entry: this.perPage,
        model: this.search
      })
    },
    searchByPostal(){
      this.currentPage=1;
      this.getPostal({ 
        page: this.currentPage, 
        entry: this.perPage,
        postal: this.searchPostal
      })
    },
    onPageChange(page) {
      //let filter = this.getFilter();
      this.getPostal({ 
        page: page, 
        entry: this.perPage,
        model: (this.search!="")?this.search:undefined,
        postal: (this.searchPostal!="")?this.searchPostal:undefined
      })
    },
    processMetadata(metadata) {
      let { totalData } = metadata;
      console.log('total data ',totalData)
      this.totalRows = totalData;
      this.tableLoading = false;
    },
    getPostal(filter) {
      axios.get(`sales-and-purchase/drcc/postal`, { params: { ...filter } }).then((response) => {
        this.postal=response.data.data  
        this.processMetadata(response.data.metadata)
        return response.data;
      }).catch((e) => {
        console.log(e);
      })
      /*
      axios.get('sales-and-purchase/drcc/postal').then((response) => {
        this.postal = response.data.data;
      }).catch((e) => {
        console.log(e)
      })
      */
    },
    // getItem(data){
    //   var searchItem = this.itemCatalog.find(x=>{return x._id==data});
    //   if(!searchItem){
    //     return 
    //   } 
    //   console.log({searchItem})
    //   return searchItem;
    // },
    editPostal(row) {
      this.$router.push(`/post-code/edit/${row.item._id}`);
    },
    deletePostal(row){
      this.modalDelete = true;
      this.selectPostal = row;
    },
    confirmDelete() {
      this.modalDelete = false
      axios.delete(`/sales-and-purchase/drcc/postal/${this.selectPostal._id}`).then(() => {
        this.$bvToast.toast(`You have successfully deleted post code ${this.selectPostal.postal}`, {
          title: "Success",
          variant: "success",
          solid: true,
        });
        this.getPostal({
          page: this.currentPage, 
          entry: this.perPage,
          model: this.search,
          postal: this.searchPostal

        })
      }).catch((e) => {
        this.$bvToast.toast(`You have failed to delete postcode ${this.selectPostal.postal}`, {
          title: "Fail",
          variant: "danger",
          solid: true,
        });
      })
    },
  },
  computed: {
    downloadFields(){
      return {
        'PLANT':'plant',
        'ITEM':'postal',
        'ITEM DESC':'itemModel',
        'SKIP SCAN':'skipScan',
      }
    },
    downloadFormat(){
      if(!this.postalAll){
        console.info('postal format no data yet');
        return []
      }
      var postalTemp=[];
      let curPostal;
      for(var x=0;x<this.postalAll.length;x++){
        curPostal=this.postalAll[x];
        postalTemp.push({
          plant:curPostal.plant,
          postal:curPostal.postal,
          itemModel:curPostal.itemModel?curPostal.itemModel:'undefined',
          skipScan:curPostal.skipScan||"false"
        })
      }
      
      //console.info('postal temp ',postalTemp);
      return postalTemp;
    },
    permission() {
      return userAccess("Post Code", "masterdata_menu");
    },
  },
  created() {
    document.title = 'Post Code | RSP';
    /*
    axios.get(`sales-and-purchase/drcc/postal`).then((response) => {
        this.postalAll=response.data.data  
        return response.data;
      }).catch((e) => {
        console.log(e);
      })
    */
    this.getPostal({
      page: this.currentPage, 
      entry: this.perPage,
    })
    //temporary get api items from axios
    /*
      axios.get('sales-and-purchase/drcc/items?total=100').then((response) => {
        this.itemCatalog = response.data.data;
        // console.log(this.itemCatalog)
      }).catch((e) => {
        console.log(e)
      })
    */
  },
}
</script>
<style scoped>
@media (min-width: 1423px) {
  .hide-on-mobile { display: none !important; }
  .show-on-desktop {display: inline !important;}
}

@media (max-width: 1422px) {
  .hide-on-desktop { display: none !important; }
}
</style>